import { useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import { withTranslation, TFunction } from "react-i18next";
import { connect } from "react-redux";
import filter from "lodash/filter";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import queryString from "query-string";
import analyticsSegmentIdentify from "src/services/SegmentIdentify";
import Analytics from "src/services/Analytics";
import config from "src/config";
import jobSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import MultiSelectBox from "src/components/common/Header/MultiSelectBox";
import LocationBoxV2 from "src/components/common/Header/LocationBoxV2";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";

interface LocationSpecialitiesProps {
  translate: TFunction<"translation", undefined>;
  componentName: string;
  primarySpecialty: string;
  onboardingBasicInfo: {
    firstname: string;
    lastname: string;
    preferredName: string;
    email: string;
    phone: string;
    stateLicensesHeld: string;
    gender: string;
    permanentAddress: {
      streetAddress: string | null;
      apt: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
      country: string | null;
    };
    isCurrAddressDiff: boolean;
    currentAddress: {
      streetAddress: null;
      apt: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
      country: string | null;
    };
    emergencyContact: string | null;
    emergencyPhone: string | null;
    relation: string | null;
    isHcp: string | null;
    shifts: string[];
    interests: string[];
    dateAvailable: string;
    referredBy: string | null;
    requestedRecruiter: string | null;
    travelDestinations: [];
    preferredWorkEnv: string | null;
    professionalJourney: string | null;
  };
  userId: number;
  email: string;
  enableDashboardAd: boolean;
  hideHeading?: boolean;
}

const enableJobLocationSearch = config.ENABLE_SEARCH_JOB_LOCATION === "true";

const LocationSpecialities = ({
  translate,
  primarySpecialty,
  onboardingBasicInfo,
  userId,
  email,
  componentName,
  enableDashboardAd,
  hideHeading,
}: LocationSpecialitiesProps) => {
  const isCompact = useMediaQuery("(max-width: 768px)");
  const { search } = useLocation();
  const [location, setLocation] = useState(jobSearchQueryBuilder.getDefaultLocation());
  const [specialties, setSpecialties] = useState(
    jobSearchQueryBuilder.getDefaultSpecialties([[primarySpecialty, primarySpecialty]])
  );

  const [defaultLocation, setDefaultLocation] = useState(
    jobSearchQueryBuilder.serializeLocation(location)
  );

  const queryStringValues = search ? queryString.parse(search) : {};
  const {
    shiftTypes: queryShiftTypes,
    startDate: queryStartDate,
    shiftLengths: queryShiftLengths,
    durations: queryDurations,
    salaryMax: querySalaryMax,
    jobDetails: queryJobDetails,
    msp: queryMsp,
    datePosted: queryDatePosted
  } = queryStringValues;

  useEffect(() => {
    const queriedStringValues = search ? queryString.parse(search) : {};
    if (queriedStringValues?.specialty) {
      const newSpeciality = jobSearchQueryBuilder.getDefaultSpecialties([
        [primarySpecialty, primarySpecialty],
      ]);
      setSpecialties(newSpeciality);
    }
    if (queriedStringValues?.location && typeof queriedStringValues.location === "string") {
      setDefaultLocation(queriedStringValues.location);
    }
  }, [search, primarySpecialty]);
  const specialtySetter = (newSpecialty) => {
    const specialtiesCopy: [string, string][] = specialties;
    if (!specialties.some((specialtyElement) => specialtyElement[0] === newSpecialty[0])) {
      specialtiesCopy.push(newSpecialty);
    }
    setSpecialties(Array.from(new Set(specialtiesCopy)));
  };



  //! TODO: This is an ugly fix to handle first time login users not seeing specialties. Fix this in future versions.
  useEffect(() => {
    setSpecialties(
      jobSearchQueryBuilder.getDefaultSpecialties([[primarySpecialty, primarySpecialty]])
    );
  }, [primarySpecialty]);

  const handleDelete = (item) => {

    if (isMobile) {
      const activeElement = document.activeElement as HTMLInputElement | HTMLTextAreaElement;
      activeElement.inputMode = "none";
      document.getElementById("jobSearchMainV2")!.scrollIntoView();
    }

    const copiedObject = filter(specialties, (specialty) => specialty[0] !== item);
    setSpecialties(copiedObject);
  };

  const handleDeleteAll = () => {
    setSpecialties([]);
  };

  const enableFilters = config.ENABLE_FILTERS === "true";
  const initialJobsSearch = enableFilters ? 9 : 12;
  const enableJobDetailsFilter = config.ENABLE_JOB_DETAILS_FILTER === "true";
  const disableShiftTypeFilter = config.DISABLE_SHIFT_TYPE_FILTER === "true";

  const shiftTypes =
    enableFilters && onboardingBasicInfo && onboardingBasicInfo.shifts && !disableShiftTypeFilter
      ? onboardingBasicInfo.shifts
        .map((shift) => {
          const lowerCaseShift = shift.toLowerCase();
          if (lowerCaseShift === "day") {
            return "days";
          }
          if (lowerCaseShift === "mid") {
            return "evenings";
          }
          if (lowerCaseShift === "night") {
            return "nights";
          }
          return "";
        })
        .join(",")
      : null;

  const momentObject = enableFilters
    ? onboardingBasicInfo?.dateAvailable && moment(onboardingBasicInfo?.dateAvailable, "MM/DD/YYYY")
    : null;
  const startDate =
    momentObject && momentObject.diff(moment(), "day") >= 0
      ? moment(onboardingBasicInfo?.dateAvailable).format("YYYY-MM-DD")
      : null;

  const isJobResultPage = window.location.pathname.includes("result");

  const getStartDate = (
    shouldUseQueryStartDate: boolean,
    theQueryStartDate: string | (string | null)[] | null,
    enableTheJobDetailsFilter: boolean,
    defaultStartDate: string | null
  ): string | (string | null)[] | null => {
    if (shouldUseQueryStartDate) {
      return theQueryStartDate;
    }

    const shouldUseDefaultStartDate = !enableTheJobDetailsFilter;
    return shouldUseDefaultStartDate ? defaultStartDate : null;
  };

  const searchJobs = async () => {

    if (specialties.length === 0) {
      return;
    }
    jobSearchQueryBuilder.setIsSearchClicked(true);

    if (location && (document.getElementById("country-select") as HTMLInputElement)?.value !== "") {
      jobSearchQueryBuilder.setInitialParams();
      setDefaultLocation(jobSearchQueryBuilder.serializeLocation(location));
      jobSearchQueryBuilder.handleParamsChange({
        location: jobSearchQueryBuilder.serializeLocation(location),
        specialty: jobSearchQueryBuilder.serializeSpecialties(specialties),
        tags: [],
        view: translate("jobSearch.defaultView"),
        geoLocationRadius: config.GEO_LOCATION_RADIUS,
        sort: translate("jobSearch.defaultSort"),
        offset: translate("jobSearch.defaultOffset"),
        startDate: getStartDate(isJobResultPage, queryStartDate, enableJobDetailsFilter, startDate),
        shiftTypes: isJobResultPage ? queryShiftTypes : shiftTypes,
        refreshPayFilter: "true",
        refreshFacilityFilter: "true",
        shiftLengths: isJobResultPage ? queryShiftLengths : "",
        durations: isJobResultPage ? queryDurations : "",
        facilityNames: "",
        salaryMax: isJobResultPage ? querySalaryMax : "",
        size: initialJobsSearch,
        jobDetails: isJobResultPage ? queryJobDetails : "",
        msp: isJobResultPage ? queryMsp : "",
        datePosted: isJobResultPage ? queryDatePosted : "",
      });
    } else {
      jobSearchQueryBuilder.setInitialParams();
      jobSearchQueryBuilder.handleParamsChange({
        specialty: jobSearchQueryBuilder.serializeSpecialties(specialties),
        location: "{}",
        tags: [],
        view: translate("jobSearch.defaultView"),
        geoLocationRadius: config.GEO_LOCATION_RADIUS,
        sort: translate("jobSearch.defaultSort"),
        offset: translate("jobSearch.defaultOffset"),
        startDate: getStartDate(isJobResultPage, queryStartDate, enableJobDetailsFilter, startDate),
        shiftTypes: isJobResultPage ? queryShiftTypes : shiftTypes,
        refreshPayFilter: "true",
        refreshFacilityFilter: "true",
        shiftLengths: isJobResultPage ? queryShiftLengths : "",
        facilityNames: "",
        durations: isJobResultPage ? queryDurations : "",
        salaryMax: isJobResultPage ? querySalaryMax : "",
        size: initialJobsSearch,
        jobDetails: isJobResultPage ? queryJobDetails : "",
        msp: isJobResultPage ? queryMsp : "",
        datePosted: isJobResultPage ? queryDatePosted : "",
      });
    }
    const where = jobSearchQueryBuilder.getLocationsArray(location);
    if (
      userId &&
      email &&
      sessionStorage.getItem("isSegmentIdentifyCalledFromSearchJob") === null
    ) {
      sessionStorage.setItem("isSegmentIdentifyCalledFromSearchJob", "true");
      analyticsSegmentIdentify({ translate, primarySpecialty, onboardingBasicInfo, userId, email });
    }
    const segmentEventName = translate("segment.jobSearched");
    const segmentEventProps = {
      segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
      what: jobSearchQueryBuilder.getSpecialtiesArray(specialties),
      origin: document.referrer,
      where,
      has_add_compact_states_checked: location?.compactStateCheckValue || false,
      has_multiple_locations: where?.length > 1
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps)
    window.scrollTo(0, 0);
  };

  return (
    <>
      {hideHeading !== true &&!enableDashboardAd ? (<h3 className="jobSearchHeading" id="job-search-heading">Find your next destination</h3>) : null}
      <div className={clsx(enableJobLocationSearch && "jobSearchSpecialNewFeature", !isMobile && enableDashboardAd && "mt-4")}>
        <div className={clsx("jobSearchLocation", enableDashboardAd && isMobile && "jobSearchInBanner")}>
          <div className="jobLocationTxt">{translate("dashboard.searchBar.location")}</div>
          <LocationBoxV2 enableDashboardAd={enableDashboardAd} defaultLocation={defaultLocation} setLocation={setLocation} componentName={componentName} />
        </div>
        <div id="specialityBox" className={clsx("jobSearchSpecialties", enableDashboardAd && isMobile && "jobSearchInBanner")}>
          <div className="jobSpecialtiesTxt">{translate("dashboard.searchBar.specialties")}</div>
          <MultiSelectBox
            specialties={specialties}
            specialtySetter={specialtySetter}
            handleDelete={handleDelete}
            handleDeleteAll={handleDeleteAll}
            enableDashboardAd={enableDashboardAd}
          />
        </div>
        <div className="jobSearchBtnMain">
          <Button className="jobSearchBtn" disabled={specialties.length === 0} onClick={searchJobs}>
            {!isCompact ? (
              <SearchIcon className="jobSearchIcon" />
            ) : (
              <>
                <SearchIcon className="jobSearchIcon mr-1" />{" "}
                {translate("dashboard.searchBar.search")}
              </>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

LocationSpecialities.defaultProps = {
  hideHeading: false,
};

const mapStateToProps = (state) => ({
  email: state.auth.email,
  userId: state.auth.userId,
  primarySpecialty: state.onBoarding.specialtiesAndEducation?.specialty?.primarySpecialty,
  onboardingBasicInfo: state.onBoarding.basicInfo,
});

export default connect(mapStateToProps)(withTranslation()(LocationSpecialities));
