import React from "react";
import { isLoggedIn } from "axios-jwt";
import Container from "react-bootstrap/Container";
import HeaderV2 from "../common/Header/HeaderV2";
import FooterV2 from "../common/Footer/FooterV2";
import styles from "../components.module.scss"

const MainLayout = ({ component: Component = () => <></>, children = <></>, ...otherProps }) => {
  const header = (
    <>
      <div className="fixHeader" id="fixHeader">
        <Container>
          <HeaderV2 />
        </Container>
      </div>
    </>
  );

  const footer = (
    <footer style={{ "backgroundColor": styles?.colorPrimary }}>
      <FooterV2 adjustFooter={otherProps.adjustFooter} />
    </footer>
  );

  const headerToJobSearch = isLoggedIn() ? header : null;
  const footerOnDashboard = isLoggedIn() ? footer : null;

  return (
    <>
      {headerToJobSearch}
      <div className="pageWrapper">
        {Component != null && <Component {...otherProps} />}
        {children != null && children}
      </div>
      {footerOnDashboard}
    </>
  );
};

export default MainLayout;
