/* eslint-disable react/jsx-props-no-spreading */
import { jobDetailType } from "src/components/common/MainContent/JobResults/common";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LightModeIcon from "@mui/icons-material/LightMode";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EligibilityMeter, { EligibilityMeterRequirement } from "src/components/common/EligibilityMeter/EligibilityMeter";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { Skeleton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation, withTranslation } from "react-i18next";
import { ReactElement } from "react";
import SyncIcon from "@mui/icons-material/Sync";
import NightlightIcon from "@mui/icons-material/Nightlight";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import JobCardChip, { JobCardChipProps } from "../chip/JobCardChip";
import styles from "./JobCardContent.module.scss";
import JobDisclaimerToolTip from "../../../JobResults/JobDisclaimerToolTip";
import "./override.scss";

export
  const renderShiftDetails = (job: jobDetailType) => {
    const shift = job?.shift?.toLowerCase();

    if (shift) {
      const hoursAmount = shift.split("hr")?.[0];
      let type: string | undefined = shift.split(" ")?.[1];
      let icon: ReactElement | undefined;

      switch (type) {
        case "days":
          type = "Days";
          icon = <LightModeIcon />;
          break;
        case "evenings":
          type = "Mid";
          icon = <Brightness6Icon />;
          break;
        case "nights":
          type = "Nights";
          icon = <NightlightIcon />;
          break;
        case "rotating":
          type = "Rotating d/e";
          icon = <SyncIcon />;
          break;
        default:
          type = undefined;
          icon = undefined;
          break;
      }

      if (hoursAmount && type && icon) {
        return (
          <>
            {icon} {hoursAmount}Hr {type}
          </>
        )
      }
    }

    return "";
  }

export interface JobCardContentProps {
  job?: jobDetailType;
  chips?: JobCardChipProps[];
  secondaryInfo?: "quick-apply" | "views";
  compact?: boolean;
  className?: string;
  onClick?: () => void;
}

const JobCardContent = ({
  job,
  chips,
  secondaryInfo,
  compact,
  className,
  onClick,
}: JobCardContentProps) => {
  const { t } = useTranslation();

  if (job == null) {
    return (
      <div className={clsx(styles.main, className)}>
        {Array.from({ length: 5 }).map((_, index) => {
          const isLast = index === 4;
          const marginBottomAmount = compact ? "5px" : "10px";
          const marginBottom = isLast ? 0 : marginBottomAmount;

          return <Skeleton
            key={uuidv4()}
            variant="rectangular"
            className={styles.skeleton}
            height={compact ? "10px" : "20px"}
            width="100%"
            style={{ marginBottom, borderRadius: "5px" }}
          />
        })}
      </div>
    );
  }

  const eligibilityMeterRequirements: EligibilityMeterRequirement[] = [
    { isMet: job?.quickApplyEligibility?.qualifications?.ssnAndDob?.quickApply === true },
    { isMet: job?.quickApplyEligibility?.qualifications?.workHistory?.quickApply === true },
    { isMet: job?.quickApplyEligibility?.qualifications?.reference?.quickApply === true },
    ...(job?.quickApplyEligibility?.qualifications?.credential?.credentials != null &&
      job?.quickApplyEligibility?.qualifications?.credential?.credentials?.length > 0 ? [{
        isMet: job?.quickApplyEligibility?.qualifications?.credential?.quickApply === true
      }] : []),
    { isMet: job?.quickApplyEligibility?.qualifications?.checkList?.quickApply === true }
  ];

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => onClick?.()}
      onClick={() => onClick?.()}
      className={clsx(styles.main, compact && styles.compact, compact && "compact", className)}
    >
      <div>
        {chips != null && chips.length > 0 && (
          <div className={styles.chips}>
            {chips.map((chipProps: JobCardChipProps) => (
              <JobCardChip
                key={uuidv4()}
                {...chipProps}
                size="compact"
                className={clsx(styles.chip, chipProps.className)}
              />
            ))}
          </div>
        )}
      </div>
      <div className={styles.locationAndSecondaryInfo}>
        <div>
          <div className={styles.cityState}>
            <LocationOnIcon />
            {job.city}, {job.state}
          </div>
          <div className={styles.facilityName}>
            {job.facilityName}
          </div>
        </div>
        {secondaryInfo != null && (
          <div className={styles.secondaryInfo}>
            {secondaryInfo === "quick-apply" && (
              <EligibilityMeter
                requirements={eligibilityMeterRequirements}
                showQuickApplyText
                eligibilityPercent={job.quickApplyPercentage}
              />
            )}

            {secondaryInfo === "views" && (
              <div className={styles.viewsContainer}>
                <VisibilityIcon />
                {(job?.views ?? 0).toLocaleString()} {" "}
                {t("dashboard.jobsViews.views")}
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles.salary}>
        {job.salaryMax != null ? `$${job.salaryMax.toLocaleString()}` : "Unknown"}/Weekly
        <JobDisclaimerToolTip
          translate={t}
          isJobDetail={false}
          salaryHourly={job.salaryHourly}
          salaryWeekly={job.salaryExpenseReimbursementWeekly}
          isSalary
        />

      </div>

      <div className={styles.professionAndSpecialty}>
        {job.professionDescription} / {job.specialtyDescription}
      </div>

      <div className={styles.extraInfo}>
        <div className={styles.shift}>
          {renderShiftDetails(job)}
        </div>

        <div className={styles.hoursPerWeek}>
          <AccessTimeIcon />
          {job.hoursPerWeek}Hrs/Wk
        </div>

        <div className={styles.duration}>
          <CalendarTodayIcon />
          {job.duration} Wk
        </div>
      </div>
    </div>
  );
}

JobCardContent.defaultProps = {
  job: undefined,
  chips: [],
  secondaryInfo: "",
  compact: false,
  className: "",
  onClick: undefined,
}

export default withTranslation()(JobCardContent);

